import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout, PageTitle } from '../components/layout'
import { BlogItem } from '../components/BlogItem'

const BlogPage = () => {
    const data = useStaticQuery(graphql`
        {
            allSanityPost {
                edges {
                    node {
                        _key
                        title
                        slug {
                            current
                        }
                        featuredImage {
                            asset {
                                gatsbyImageData(
                                    aspectRatio: 1,
                                    width: 800,
                                )
                            }
                            alt
                        }
                        date(formatString: "MMMM Do YYYY")
                    }
                }
            }
        }
    `)

    const seo = {
        title: `Blog`,
        description: `Steve Treseler's Creative Music Blog.`
    }

    return (
        <Layout seo={seo}>
            <PageTitle title='Blog' />
            <Wrapper>
                {data.allSanityPost.edges.map(blogItem => {
                    return (
                        <BlogItem input={blogItem.node} key={blogItem.node._key} />
                    )
                })}
            </Wrapper>
        </Layout>
    )
}

export default BlogPage

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
`